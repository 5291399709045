import { LottoBall, LottoBallVariant } from '@/components/LottoBall';
import { OddsVariant } from '@/config/odds/OddsVariant';
import numberToCurrency from '@/utilities/numberToCurrency';
import numberToRomanNumber from '@/utilities/numberToRomanNumber';
import classnames from 'classnames';
import numeral from 'numeral';
import React from 'react';

interface Collection {
  winningClass: number;
  winningNumber1: string;
  winningNumber2: string | null;
  numberOfWinners?: number;
  odds: number;
  oddsDescription: string;
}

interface IDrawNumbersCollectionItem {
  index: number;
  drawNumber: number;
  drawNumberType?: 1 | 0;
}
interface DrawNumbersCollectionProps {
  superNumber: number | null; // for lotto
  superNumber2: number | null; // for lotto
  extraNumber: number | null; // for lotto
  drawNumbersCollection?: IDrawNumbersCollectionItem[]; // lotto and eurojackpot
  small?: boolean;
  variant: OddsVariant;
  drawNumbers?: string; // for KENO
  collection?: Collection[]; // for gluecksspirale
  currency?: { name: string }; // for gluecksspirale
}

const DrawNumbersCollection: React.FC<
  React.PropsWithChildren<DrawNumbersCollectionProps>
> = ({
  drawNumbersCollection = [],
  superNumber,
  extraNumber,
  small,
  variant,
  drawNumbers = null,
  collection = null,
  currency = { name: '€' },
}) => {
  let sortedCollection;
  let sortedExtraCollection;
  let sortedNumbers;
  switch (variant) {
    case OddsVariant.Eurojackpot:
      sortedCollection = drawNumbersCollection
        .filter((drawNumber) => drawNumber.drawNumberType === 0)
        .sort((a, b) => (a.drawNumber < b.drawNumber ? -1 : 1));

      sortedExtraCollection = drawNumbersCollection
        .filter((drawNumber) => drawNumber.drawNumberType === 1)
        .sort((a, b) => (a.drawNumber < b.drawNumber ? -1 : 1));

      break;

    case OddsVariant.TotoAW:
    case OddsVariant.TotoEW:
      sortedCollection = [];
      sortedExtraCollection = [];
      if (drawNumbers) {
        const allNumbers = drawNumbers.split(',');
        let counter = 0;
        for (const number of allNumbers) {
          counter++;
          const currentNumber = {
            key: variant + counter,
            drawNumber: number,
            drawNumberType: 0,
          };
          // Zusatzspiel nur bei AW
          if (variant === OddsVariant.TotoAW && counter === allNumbers.length) {
            currentNumber.drawNumberType = 1;
            sortedExtraCollection.push(currentNumber);
            break;
          }
          sortedCollection.push(currentNumber);
        }
      }
      break;

    case OddsVariant.Lotto6aus49:
      sortedCollection = drawNumbersCollection.sort((a, b) =>
        a.drawNumber < b.drawNumber ? -1 : 1
      );

      break;

    case OddsVariant.Keno:
      sortedNumbers = drawNumbers
        ? drawNumbers
            .split(',')
            .sort((a, b) => +a - +b)
            .map((numb) => +numb)
        : [];
      break;
  }

  const rowClassName = classnames('OddsTableRow', {
    'OddsTableRow--small': small,
  });

  return (
    <>
      {drawNumbersCollection && (
        <div
          className={classnames('DrawNumbersCollection', {
            'DrawNumbersCollection--small': small || false,
            'DrawNumbersCollection--keno': variant === OddsVariant.Keno,
            'DrawNumbersCollection--table':
              variant === OddsVariant.GluecksSpirale,
          })}
        >
          {(variant === OddsVariant.Eurojackpot ||
            variant === OddsVariant.Lotto6aus49) && (
            <div className="DrawNumbersCollection__container">
              {sortedCollection.map((collectionItem) => (
                <LottoBall
                  value={collectionItem.drawNumber}
                  key={collectionItem.index}
                  variant={
                    variant === OddsVariant.Eurojackpot
                      ? LottoBallVariant.Eurojackpot
                      : LottoBallVariant.Default
                  }
                  small={small}
                />
              ))}
            </div>
          )}

          {variant === OddsVariant.Lotto6aus49 && (
            <>
              {extraNumber !== null && (
                <div className="DrawNumbersCollection__container">
                  <div className="DrawNumbersCollection__label">Zusatzzahl</div>
                  <LottoBall
                    value={extraNumber}
                    variant={LottoBallVariant.Default}
                    small={small}
                  />
                </div>
              )}

              {superNumber !== null && (
                <div className="DrawNumbersCollection__container">
                  <div className="DrawNumbersCollection__label">Superzahl</div>
                  <LottoBall
                    value={superNumber}
                    variant={LottoBallVariant.Default}
                    small={small}
                  />
                </div>
              )}
            </>
          )}
          {variant === OddsVariant.Eurojackpot && (
            <div className="DrawNumbersCollection__container">
              <div className="DrawNumbersCollection__label">Eurozahlen</div>
              {sortedExtraCollection.map((collectionItem) => {
                return (
                  <LottoBall
                    value={collectionItem.drawNumber}
                    key={collectionItem.index}
                    variant={LottoBallVariant.Eurojackpot}
                    small={small}
                  />
                );
              })}
            </div>
          )}

          {variant === OddsVariant.Keno && sortedNumbers && (
            <div className="DrawNumbersCollection__container">
              {sortedNumbers.map((value, index) => {
                return (
                  <LottoBall
                    value={value}
                    key={index}
                    variant={LottoBallVariant.Keno}
                    small={small}
                  />
                );
              })}
            </div>
          )}

          {(variant === OddsVariant.TotoAW || variant === OddsVariant.TotoEW) &&
            drawNumbers && (
              <>
                <div className="DrawNumbersCollection__container">
                  {sortedCollection.map((collectionItem, index) => (
                    <LottoBall
                      value={collectionItem.drawNumber}
                      key={index}
                      variant={LottoBallVariant.Default}
                      small={small}
                    />
                  ))}
                </div>
                {sortedExtraCollection.length > 0 && (
                  <div className="DrawNumbersCollection__container">
                    <div className="DrawNumbersCollection__label">
                      Zusatzspiel
                    </div>
                    {sortedExtraCollection.map((collectionItem, index) => (
                      <LottoBall
                        value={collectionItem.drawNumber}
                        key={index}
                        variant={LottoBallVariant.Default}
                        small={small}
                      />
                    ))}
                  </div>
                )}
              </>
            )}

          {variant === OddsVariant.GluecksSpirale && collection && (
            <table
              className={classnames('OddsTableContainer__table', {
                'h-spacer-large-top': !small,
              })}
            >
              <thead>
                <tr className={classnames(rowClassName, 'OddsTableRow--head')}>
                  <th>Gewinn&shy;klasse</th>
                  <th>Gewinn&shy;zahlen</th>
                  {!small && (
                    <>
                      <th>Gewinne*</th>
                      <th>Quoten</th>
                      <th>Gewinne* / Quoten</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {collection
                  .slice(0, small ? 4 : collection.length)
                  .map((winningClass, idx) => {
                    const quoten: string =
                      `${numberToCurrency(
                        winningClass.odds || 0,
                        currency.name,
                        true,
                        0,
                        0
                      )}` +
                      (winningClass.oddsDescription
                        ? `<br>(${winningClass.oddsDescription
                            .replace(/EUR/, '€')
                            .replace(',00', '')})**`
                        : '');

                    const formattedNumberOfWinners = numeral(
                      winningClass.numberOfWinners || 0
                    ).format('0,0.[00]');

                    return (
                      <tr className={rowClassName} key={idx}>
                        <td>
                          {numberToRomanNumber(winningClass.winningClass)}
                          {winningClass.winningClass === 6 ? '***' : ''}
                          {winningClass.winningClass === 7 ? '****' : ''}{' '}
                        </td>
                        <td>
                          {winningClass.winningNumber1.padStart(7, '–')}
                          {winningClass.winningNumber2 && (
                            <>
                              <br />
                              {winningClass.winningNumber2.padStart(7, '–')}
                            </>
                          )}
                        </td>
                        {!small && (
                          <>
                            <td>{formattedNumberOfWinners} x</td>
                            <td dangerouslySetInnerHTML={{ __html: quoten }} />
                            <td
                              dangerouslySetInnerHTML={{
                                __html: `${formattedNumberOfWinners} x /<br>${quoten}`,
                              }}
                            />
                          </>
                        )}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </div>
      )}
    </>
  );
};

export default DrawNumbersCollection;
