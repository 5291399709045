import { Button } from '@/components/Button';
import DrawNumbersCollection from '@/components/DrawNumbersCollection';
import { Icon, IconVariant } from '@/components/Icon';
import LinkWrapper from '@/components/LinkWrapper';
import { LoadingIcon } from '@/components/LoadingIcon';
import Logo from '@/components/Logo';
import { LogoVariant } from '@/components/LogoVariant';
import Modal from '@/components/Modal';
import ModalGluecksspiraleInfo from '@/components/ModalGluecksspiraleInfo';
import { WinningNumbersAdditionalGame } from '@/components/WinningNumbersAdditionalGame';
import { eurojackpot } from '@/config/game/eurojackpot';
import { gluecksSpirale } from '@/config/game/gluecksSpirale';
import { keno } from '@/config/game/keno';
import { lotto6aus49 } from '@/config/game/lotto6aus49';
import { plus5 } from '@/config/game/plus5';
import { spiel77 } from '@/config/game/spiel77';
import { super6 } from '@/config/game/super6';
import { OddsVariant } from '@/config/odds/OddsVariant';
import { DateFormat, formatDate } from '@/utilities/dateUtils';
import classnames from 'classnames';
import React from 'react';

interface IDrawNumbersCollection {
  index: number;
  drawNumber: number;
  drawNumberType?: 1 | 0;
}

interface IGluecksspiraleClassCollection {
  winningClass: number;
  winningNumber1: string;
  winningNumber2: string | null;
  odds: number;
  oddsDescription: string;
}

interface IWinningNumbers {
  drawDate?: number;
  drawNumbersCollection?: IDrawNumbersCollection[];
  drawNumbers?: string;
  gluecksspiraleClassCollection?: IGluecksspiraleClassCollection[];
  superNumber: number | null;
  superNumber2: number | null;
  extraNumber: number | null;
  game77?: { numbers: string };
  super6?: { numbers: string };
  plus5?: string;
  currency?: { name: string };
}

export interface WinningNumbersProps {
  winningNumbers: IWinningNumbers | null;
  showDate?: boolean;
  showHeader?: boolean;
  showLogoHeader?: boolean;
  showWinningClass?: boolean;
  showFooter?: boolean;
  hideAdditional?: boolean;
  hideMain?: boolean;
  showMore?: boolean;
  small?: boolean;
  clickable?: boolean;
  variant: OddsVariant;
  showLargeHeader?: boolean;
  children?: React.ReactNode;
}

const oddsVariantLogos = {
  [OddsVariant.Lotto6aus49]: LogoVariant.Lotto6aus49,
  [OddsVariant.Eurojackpot]: LogoVariant.Eurojackpot,
  [OddsVariant.Keno]: LogoVariant.Keno,
  [OddsVariant.GluecksSpirale]: LogoVariant.GluecksSpirale,
  [OddsVariant.TotoAW]: LogoVariant.TotoAW,
  [OddsVariant.TotoEW]: LogoVariant.TotoEW,
};

const oddsVariantUrls = {
  [OddsVariant.Lotto6aus49]: lotto6aus49.urls.stats,
  [OddsVariant.Eurojackpot]: eurojackpot.urls.stats,
  [OddsVariant.Keno]: keno.urls.stats,
  [OddsVariant.GluecksSpirale]: gluecksSpirale.urls.stats,
};

export class WinningNumbers extends React.Component<WinningNumbersProps> {
  private get classNames(): string {
    return classnames(
      'WinningNumbers',
      `WinningNumbers--${this.props.variant}`,
      {
        'WinningNumbers--small': this.props.small || false,
      }
    );
  }

  private get collection() {
    const numbers = this.props.winningNumbers;
    const classCollection = numbers
      ? numbers.gluecksspiraleClassCollection
      : [];
    return classCollection
      ? classCollection.sort((a, b) => {
          return typeof a.winningClass === 'number' &&
            typeof b.winningClass === 'number'
            ? b.winningClass - a.winningClass
            : 0;
        })
      : [];
  }

  private renderWinningNumbers(): JSX.Element | null {
    const { showDate, winningNumbers } = this.props;
    let drawDate: number | null = null;
    if (showDate && winningNumbers && winningNumbers.drawDate) {
      drawDate = winningNumbers.drawDate;
    }
    return (
      <>
        {this.props.showLogoHeader &&
          (this.props.showLargeHeader ? (
            <div
              className={classnames(
                'WinningNumbers__logoHeader',
                `OddsTableContainer__header--${this.props.variant}`,
                `OddsTableContainer__header`
              )}
            >
              <Logo
                className="OddsTableContainer__logo"
                variant={oddsVariantLogos[this.props.variant]}
              />
              <span>Gewinnzahlen</span>
            </div>
          ) : (
            <div
              className={classnames(
                'WinningNumbers__logoHeader',
                `WinningNumbers__logoHeader--${this.props.variant}`
              )}
            >
              <Logo
                variant={oddsVariantLogos[this.props.variant]}
                lazyload={true}
              />
            </div>
          ))}
        <div className="WinningNumbers__content">
          {this.props.showHeader && (
            <h2 className="WinningNumbers__header">Gewinnzahlen</h2>
          )}

          {drawDate && this.props.showDate && (
            <span className="WinningNumbers__date">
              Ziehung vom {formatDate(drawDate, DateFormat.Extended)}
            </span>
          )}

          {this.props.winningNumbers !== null ? (
            <>
              {this.props.variant === OddsVariant.Keno && (
                <>
                  {this.props.children}
                  {this.props.winningNumbers.drawNumbers &&
                    !this.props.hideMain && (
                      <DrawNumbersCollection
                        variant={this.props.variant}
                        drawNumbers={this.props.winningNumbers.drawNumbers}
                        small={this.props.small}
                        {...this.props.winningNumbers}
                      />
                    )}
                </>
              )}

              {(this.props.variant === OddsVariant.TotoAW ||
                this.props.variant === OddsVariant.TotoEW) && (
                <DrawNumbersCollection
                  variant={this.props.variant}
                  collection={this.collection}
                  small={this.props.small}
                  {...this.props.winningNumbers}
                />
              )}

              {this.props.variant === OddsVariant.GluecksSpirale && (
                <>
                  {this.props.children}
                  <DrawNumbersCollection
                    variant={this.props.variant}
                    collection={this.collection}
                    currency={this.props.winningNumbers.currency}
                    small={this.props.small}
                    {...this.props.winningNumbers}
                  />
                </>
              )}
              {!this.props.hideMain &&
                (this.props.variant === OddsVariant.Lotto6aus49 ||
                  this.props.variant === OddsVariant.Eurojackpot) && (
                  <DrawNumbersCollection
                    variant={this.props.variant}
                    {...this.props.winningNumbers}
                    small={this.props.small}
                  />
                )}
              {!this.props.hideAdditional && (
                <>
                  {this.props.variant === OddsVariant.Lotto6aus49 && (
                    <div className="WinningNumbers__additionalGames">
                      <WinningNumbersAdditionalGame
                        numbers={
                          this.props.winningNumbers.game77
                            ? this.props.winningNumbers.game77.numbers
                            : '-'
                        }
                        color={spiel77.color}
                        title={spiel77.title}
                        logo={spiel77.logoColor}
                        small={this.props.small}
                      />
                      <WinningNumbersAdditionalGame
                        numbers={
                          this.props.winningNumbers.super6
                            ? this.props.winningNumbers.super6.numbers
                            : '-'
                        }
                        color={super6.color}
                        title={super6.title}
                        logo={super6.logoColor}
                        small={this.props.small}
                      />
                    </div>
                  )}
                  {this.props.variant === OddsVariant.Keno &&
                    this.props.winningNumbers.plus5 && (
                      <div className="WinningNumbers__additionalGames">
                        <WinningNumbersAdditionalGame
                          numbers={
                            this.props.winningNumbers.plus5
                              ? this.props.winningNumbers.plus5
                              : '-'
                          }
                          color={plus5.color}
                          title={plus5.title}
                          logo={plus5.logoColor}
                          small={this.props.small}
                        />
                      </div>
                    )}
                </>
              )}
              {this.props.showFooter && (
                <>
                  {this.props.variant === OddsVariant.GluecksSpirale ? (
                    <div className="WinningNumbers__notice">
                      <ModalGluecksspiraleInfo />
                      <span
                        className="WinningNumbers__info"
                        onClick={() => Modal.open('gluecksspirale-info')}
                      >
                        {this.props.small !== true ? '*/**/' : ''}
                        {'***/****'} anzeigen
                      </span>
                      <span>Alle Angaben ohne Gewähr.</span>
                    </div>
                  ) : (
                    <div className="WinningNumbers__notice">
                      Alle Angaben ohne Gewähr.
                    </div>
                  )}
                </>
              )}
              {this.props.showMore && (
                <div className="WinningNumbers__showMore">
                  <Button
                    variant={Button.Variant.Text}
                    ariaLabel="Mehr erfahren"
                  >
                    <span>Mehr erfahren</span>
                    <Icon variant={IconVariant.ArrowRight} />
                  </Button>
                </div>
              )}
            </>
          ) : (
            <div className="WinningNumbers__loading">
              <LoadingIcon isLoading={true} />
            </div>
          )}
        </div>
      </>
    );
  }

  render() {
    if (this.props.clickable) {
      return (
        <LinkWrapper
          href={oddsVariantUrls[this.props.variant]}
          className={this.classNames}
        >
          {this.renderWinningNumbers()}
        </LinkWrapper>
      );
    }
    return <div className={this.classNames}>{this.renderWinningNumbers()}</div>;
  }
}
